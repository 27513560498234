import React from 'react';
import { NavLink } from 'react-router-dom';
import { Fragment } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline';

import logoPNG from '../assets/images/avatar/logo.png';
import profilePNG from '../assets/images/avatar/profile11.png';
// import projectsPNG from '../assets/images/avatar/projects.png';
// import contactPNG from '../assets/images/avatar/contact.png';

const navigation = [
   { name: 'Profile', to: '/', current: true },
   { name: 'Projects', to: '/projects', current: false },
   { name: 'Contact', to: '/contact', current: false },
   { name: 'Websites', to: '/', current: false },
];

function classNames(...classes) {
   return classes.filter(Boolean).join(' ');
}

const NavBar = () => {
   const currentPos = window.scrollY;
   console.log(currentPos);
   return (
      <Disclosure
         as='nav'
         className={'sticky top-0 right-0 left-0 z-40 mx-auto p-5  ...'}>
         {({ open }) => (
            <>
               <div className={''}>
                  <div className='relative flex h-16 items-center justify-between'>
                     <div className={'absolute inset-y-0 left-0 flex items-center sm:hidden '}>
                        {/* Mobile menu button*/}
                        <Disclosure.Button
                           className={
                              'inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'
                           }>
                           <span className='sr-only'>Open main menu</span>
                           {open ? (
                              <XMarkIcon
                                 className='block h-6 w-6'
                                 aria-hidden='true'
                              />
                           ) : (
                              <Bars3Icon
                                 className='block h-6 w-6'
                                 aria-hidden='true'
                              />
                           )}
                        </Disclosure.Button>
                     </div>
                     <div className={'flex flex-1 items-center justify-center sm:items-stretch sm:justify-start'}>
                        <div className='flex flex-shrink-0 items-center'>
                           <img
                              className={'block h-10 w-auto lg:hidden rounded-full'}
                              src={logoPNG}
                              alt='Your Company'
                           />
                           <img
                              className={'hidden h-10 w-auto lg:block rounded-full'}
                              src={logoPNG}
                              alt='Your Company'
                           />
                        </div>
                        <div className={'hidden sm:ml-6 sm:block'}>
                           <div className='flex space-x-4'>
                              {navigation.map((item) => (
                                 <a
                                    as='NavLink'
                                    key={item.name}
                                    href={item.to}
                                    className={classNames(
                                       item.current
                                          ? 'bg-gray-900 text-j-orange'
                                          : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                       'px-3 py-2 rounded-md text-xl md:text-2xl lg:text-3xl 2xl:text-4xl  font-medium'
                                    )}
                                    aria-current={item.current ? 'page' : undefined}>
                                    {item.name}
                                 </a>
                              ))}
                           </div>
                        </div>
                     </div>
                     <div
                        className={
                           'absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0'
                        }>
                        <button
                           type='button'
                           className={
                              'rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800'
                           }>
                           <span className='sr-only'>View notifications</span>
                           <BellIcon
                              className='h-6 w-6'
                              aria-hidden='true'
                           />
                        </button>

                        {/* Profile dropdown */}
                        <Menu
                           as='div'
                           className='relative ml-3'>
                           <div>
                              <Menu.Button
                                 className={
                                    'flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800'
                                 }>
                                 <span className='sr-only'>Open user menu</span>
                                 <img
                                    className='h-8 w-8 rounded-full'
                                    src={profilePNG}
                                    alt=''
                                 />
                              </Menu.Button>
                           </div>
                           <Transition
                              as={Fragment}
                              enter='transition ease-out duration-100'
                              enterFrom='transform opacity-0 scale-95'
                              enterTo='transform opacity-100 scale-100'
                              leave='transition ease-in duration-75'
                              leaveFrom='transform opacity-100 scale-100'
                              leaveTo='transform opacity-0 scale-95'>
                              <Menu.Items
                                 className={
                                    'absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
                                 }>
                                 <Menu.Item>
                                    {({ active }) => (
                                       <a
                                          href='/'
                                          className={classNames(
                                             active ? 'bg-gray-100' : '',
                                             'block px-4 py-2 text-sm text-gray-700'
                                          )}>
                                          Hobbies
                                       </a>
                                    )}
                                 </Menu.Item>
                                 <Menu.Item>
                                    {({ active }) => (
                                       <a
                                          href='/'
                                          className={classNames(
                                             active ? 'bg-gray-100' : '',
                                             'block px-4 py-2 text-sm text-gray-700'
                                          )}>
                                          Github
                                       </a>
                                    )}
                                 </Menu.Item>
                                 <Menu.Item>
                                    {({ active }) => (
                                       <a
                                          href='/'
                                          className={classNames(
                                             active ? 'bg-gray-100' : '',
                                             'block px-4 py-2 text-sm text-gray-700'
                                          )}>
                                          Templates
                                       </a>
                                    )}
                                 </Menu.Item>
                              </Menu.Items>
                           </Transition>
                        </Menu>
                     </div>
                  </div>
               </div>

               <Disclosure.Panel className={'sm:hidden'}>
                  <div className='space-y-1 px-2 pt-2 pb-3'>
                     {navigation.map((item) => (
                        <Disclosure.Button
                           key={item.name}
                           as='a'
                           href={item.href}
                           className={classNames(
                              item.current
                                 ? 'bg-gray-900 text-white'
                                 : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                              'block px-3 py-2 rounded-md text-base font-medium'
                           )}
                           aria-current={item.current ? 'page' : undefined}>
                           {item.name}
                        </Disclosure.Button>
                     ))}
                  </div>
               </Disclosure.Panel>
            </>
         )}
      </Disclosure>
   );
};

export default NavBar;
