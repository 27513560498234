import { useRouteError } from 'react-router-dom';

const ErrorPage = () => {
   const error = useRouteError();
   console.error(error);

   return (
      <div
         id='error-page'
         className='container pt-9'>
         <h1>This is Error 404: Page Not Found</h1>

         <br />
         <p>Sorry, an unexpected error has occurred.</p>
         <br />
         <p>
            <i>{error.statusText || error.message}</i>
         </p>
         <br />
         <br />
         <br />
         <p className='text-red-500'>
            note to self: create a sitemap here so users who ends up here have an easier way of going to the correct
            page.
         </p>
      </div>
   );
};

export default ErrorPage;
