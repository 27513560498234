import React from 'react';

import NavBar from '../page-components/navbar';
// import headImg from '../assets/images/avatar/a1.png';

const Profile = () => {
   return (
      <section className='w-screen h-auto text-center'>
         <div className='mx-auto px-0 w-full bg-intro-bg bg-right bg-no-repeat bg-cover drop-shadow-2xl saturate-150'>
            <NavBar />
            <h1 className={' text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-black'}>
               Hello, it's nice to meet you! I'm
            </h1>
            <div className={'grid grid-rows-6 auto-cols-max grid-flow-col gap-0 xl:w-3/5 xl:justify-center'}>
               <div
                  className={
                     'col-span-full row-start-2 qHD:row-start-2 px-4 xl:px-0 text-3xl sm:text-6xl md:text-7xl lg:text-8xl qHD:scale-150 tracking-widest font-extrabold backdrop-blur backdrop-saturate-0 backdrop-opacity-10 backdrop-brightness-0 w-fit place-self-center ...'
                  }>
                  Jake Sinson
               </div>
               <hr className={'col-span-full row-start-3 qHD:row-start-3 w-1/2 place-self-center border-black '} />
               <div
                  className={
                     'col-span-full row-start-4 qHD:row-start-4 px-3 xl:px-0 text-2xl sm:text-5xl md:text-6xl lg:text-7xl qHD:scale-150 tracking-widest font-semibold text-j-red italic backdrop-blur backdrop-brightness-0 backdrop-opacity-10 w-fit place-self-center ...'
                  }>
                  Developer
               </div>
            </div>
         </div>
         <h2 className='my-9'>Programming Skills</h2>
         <div className='container grid grid-rows-2 grid-cols-3 gap-4'>
            <div className=''>HTML (7.5/10)</div>
            <div className=''>CSS (7.5/10)</div>
            <div className=''>JavaScript (7.5/10)</div>
            <div className=''>Bootstrap (7/10)</div>
            <div className=''>NSIS Scripting (6/10) Created multiple installers</div>
            <div className=''>JSX (5/10)Starting to get myself familiarize with it</div>
            <div className=''>PHP (5/10)</div>
            <div className=''>Tailwind (4/10) just started looking into. This website is created using Tailwind.</div>
            <div className=''>Sass/Scss (4/10)</div>
            <div className=''>PowerShell (3/10)</div>
            <div className=''>C# (3/10) Created a couple of programs with it</div>
            <div className=''>Java (2/10) Have not used sinced college</div>
         </div>
      </section>
   );
};

export default Profile;
