import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider, Outlet } from 'react-router-dom';

import './index.css';
import App from './App';
// import NavBar from './page-components/navbar';
import Profile from './pages/profile';
import Projects from './pages/projects';
import Contact from './pages/contact';
import ErrorPage from './error-page.jsx';
import reportWebVitals from './reportWebVitals';

const router = createBrowserRouter([
   {
      path: '/',
      element: <App />,
      errorElement: <ErrorPage />,
      // loader: '',
      children: [
         {
            path: '/profile',
            element: <Profile />,
            // loader: '',
         },
         {
            path: 'projects',
            element: <Projects />,
            // loader: '',
         },
         {
            path: 'contact',
            element: <Contact />,
            // loader: '',
         },
      ],
   },
]);
// function AppWrapper() {
//    return (
//       <div>
//          <App />
//          <Outlet />
//       </div>
//    );
// }

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
   <React.StrictMode>
      <RouterProvider router={router} />
   </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
