import React from 'react';

// import './style.css';

const Projects = () => {
   return (
      <section className=''>
         <h1 className=''>Yo Yo Yo</h1>;<p>test -wind.</p>
      </section>
   );
};

export default Projects;
