import React /*useState*/ from 'react';
// import { BrowserRouter, Routes, Route } from 'react-router-dom';

import NavBar from './page-components/navbar';

import './App.css';

const App = () => {
   return (
      <div
         className={`w-screen h-screen bg-[url('./assets/images/avatar/coming-soon.png')] bg-no-repeat bg-cover bg-center overflow-hidden`}>
         <div className=''>
            <NavBar />
         </div>

         <div
            className={
               'absolute bottom-1/4 xl:bottom-20 qHD:bottom-52 qHD:text-9xl left-1/2 text-xl sm:text-4xl lg:text-5xl text-j-red font-extrabold text-center ...'
            }
            style={{ transform: 'translate(-50%, 50%)' }}>
            <span className=''>COMING SOON</span>
            <hr />
         </div>
      </div>
   );
};

export default App;
