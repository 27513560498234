import React from 'react';

const Contact = () => {
   return (
      <section className=''>
         <h1 className=''>Hello!</h1>
         <p>test</p>
      </section>
   );
};

export default Contact;
